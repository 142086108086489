label.custom-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 300px;
    margin-bottom: 10px;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
}

label.custom-input .name{
    display: flex;
    margin-right: 10px;
}
label.custom-input .icon{
    display: flex;
    margin-right: 5px;
    margin-left: 10px;
}

label.custom-input .icon-action{
    display: flex;
    font-size: 30px;
    cursor: pointer;
}
label.custom-input .price{
    display: flex;
    min-width: 61px;
}


label.custom-input  input.radio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 5px;
    color: black;
}

label.custom-input .players {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    /* margin-right: 10px; */
    color: black;
}

label.custom-input input[type='radio'] {
    accent-color: #232323;
}