section.login-page {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: black;
}


section.login-page div.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Audiowide", sans-serif;
    letter-spacing: 1px;
    width: 100%;
    height: 200px;
    color: white;
    font-weight: 300;
    font-size: 40px;
    gap: 10px;
}

section.login-page div.banner span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Audiowide", sans-serif;
    letter-spacing: 1px;
    height: 200px;
    color: white;
    font-weight: 300;
    font-size: 40px;
}


section.login-page div.banner .arrow-icon {
    position: absolute;
    left: 30px;
    height: 35px;
    width: 35px;
    color: white;
    cursor: pointer;
}

section.login-page div.banner .arrow-icon:hover {
    border: 2px solid white;
    border-radius: 50%;
    background-color: #fff;
    color: black;
}

