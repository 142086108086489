@media screen and (max-width: 700px) {
    div.user-navigation {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        justify-content: space-around;
        padding-left: 5px;
        padding-right: 5px;
        width: calc(100% - 40px);
        height: 60px;
        border-radius: 60px;
        background-color: black;
        -webkit-box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        z-index: 1;
    }
}

@media screen and (min-width: 701px){
    div.user-navigation {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        justify-content: space-around;
        padding-left: 5px;
        padding-right: 5px;
        width: 700px;
        height: 60px;
        border-radius: 60px;
        background-color: black;
        -webkit-box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        box-shadow: 0px 20px 19px -15px rgba(66, 68, 90, 1);
        z-index: 1;
    }
}


div.user-navigation span {
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    border-radius: 50%;
}

div.user-navigation span a{
    display: flex;
    text-decoration: none;
}

div.user-navigation span.active {
    background-color: #ffffff;
    border-radius: 50%;
}
div.user-navigation span .icon-nav {
    font-size: 25px;
    color: white;
}


div.user-navigation span .logout {
    font-size: 27px;
    color: red;
    cursor: pointer;
}


div.user-navigation span.active .icon-nav {
    color: black;
}

div.user-navigation span .icon-nav:hover {
    font-size: 29px;
}