section .ui-template {
    display: flex; 
    flex-direction: column;
    width: 100%;
}

section .ui-template .content {
    display: flex;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    padding: 30px 10px;
    background-color: #f6f8f8; 
    border-radius: 30px 30px 0px 0px;
    min-height: 100vh;
    /* justify-content: center; */
    align-items: center;
}

section .ui-template .header {
    display: flex;
    height: 80px;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}