div.loading-panel {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    gap: 10px;
    color: black;

}