@media screen and (max-width: 500px) {
    div.list-element {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;
    }
}


@media screen and (min-width: 501px) and (max-width: 700px) {
    div.list-element {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 350px;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;

    }
}

@media screen and (min-width: 701px) {
    div.list-element {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 450px;
        height: 80px;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        margin-bottom: 15px;

    }
}



div.list-element .room-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 3px;
}

div.list-element .room-name .name{
    font-size: 15px;
}

div.list-element .label{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: gray;
}


div.list-element .players, div.list-element  .status{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* width: 130px; */
    gap: 3px;
}



div.list-element .action{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 40px;
    text-decoration: none;
    color: black;
}
