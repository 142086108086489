
@media screen and (max-width: 500px) {
    div .date-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 350px;
        height: 60px;
        background-color: rgb(24, 21, 21);;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 20px;
    }
 }
        
@media screen and (min-width: 501px)  {
        div .date-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 400px;
            height: 60px;
            background-color: rgb(24, 21, 21);;
            border-radius: 15px;
            overflow: hidden;
            margin-bottom: 20px;
            
        }
    }

div .date-bar .change-date-button{
    display: flex;
    height: 70%;
    width: 15%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 30px;
    color: white;
}

div .date-bar .change-date-button:first-child{
    border-right: 0.6px solid white;
}

div .date-bar .change-date-button:last-child{
    border-left: 0.6px solid white;
}


div .date-bar .days{
    display: flex;
    width: 70%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: rgb(255, 255, 255);
    font-weight: 400;;
}


div .date-bar .days .day-item{
    display: flex;
    justify-content: center;
    gap: 2px;
    align-items: center;
    width: 14%;
    height: 100%;
    flex-direction: column;

}
div .date-bar .days .day-name{
    display: block;
    height: 20px;
}

div .date-bar .days .day-name.selected{
    font-weight: 600;
}

.day-name::first-letter {
    text-transform: uppercase;
}

div .date-bar .days .day{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
}


div .date-bar .days .day.selected{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
}