button.custom-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
    border: none;
    border-radius: 15px;
    background-color: rgb(0, 0, 0);
    color: white;
    cursor: pointer;
    font-weight: 400;
}