div.change-match-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 0px 20px 20px 20px;
    max-height: 80px; 
    background-color: rgb(0, 0, 0);
    border-radius: 15px;
    margin-bottom: 10px;
    /* overflow: hidden; */
    font-weight: 300;
    transition: 0.3s;
}

div.change-match-item.active {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    /* width: 100%;*/
    padding: 0px 20px 20px 20px;
    max-height: 175px; 
    /* gap:5px; */
    background-color: rgb(41 33 33);
    border-radius: 15px;
    margin-bottom: 10px;
    font-weight: 300;
    /* overflow: hidden; */
}

div.change-match-item .edit-button{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    right: 0px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #ece8e8;
    font-size: 29px;
    background-color: #423e3e;
    cursor: pointer;
}

div.change-match-item .top{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    border-radius: 0px 0px 5px 5px;
    width: 100px;
    color: black;
    background-color: #ece8e8;
}

div.change-match-item .middle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;

}

div.change-match-item .bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    border-top: 0.5px solid white;
}
div.change-match-item .bottom .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

}


div.change-match-item .bottom .container .title{
    color: white;
    font-size:13px;
}

div.change-match-item .bottom .penalty{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid white;
}


div.change-match-item .bottom .penalty .title{
    color: white;
    font-size:13px;
}

div.change-match-item .bottom .action{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
    border-radius: 7px;
    background-color: #fff;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.change-match-item span.flag{
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
}

@media screen and (max-width: 500px) {
    div.change-match-item span.country-name{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 25px;
        color: white;
        font-size: 20px;
    }
 }
    
    @media screen and (min-width: 501px)  {
        div.change-match-item span.country-name{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 25px;
            color: white;
            font-size: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }


div.change-match-item select.score{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: white;
    border: none;
    outline: none;
    color: black;
    border-radius: 10px;
    font-size: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    text-align: center;
    text-align-last: center;
}

div.change-match-item .score-inactive{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: rgb(89, 89, 89);
    color: white;
    border-radius: 10px;
    font-size: 20px;
    background-image: none;
    text-align: center;
    text-align-last: center;
}


div.change-match-item span.separator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 25px;
    background-color: rgb(60, 58, 58);
    color: white;
}
