div.custom-login-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75px;
    margin-bottom: 30px;
    width: 100%;
    padding: 15px ;
    border-radius: 15px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    transition: 0.1s;
}

div.custom-login-input:focus-within {
    -webkit-box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -5px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -5px rgba(66, 68, 90, 1);
}

div.custom-login-input label {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgb(38, 38, 38);
} 
div.custom-login-input label span {
    color: rgb(47, 44, 224);
}

div.custom-login-input input {
    height: 25px;
    border: none;
    outline: none;
    font-size: 15px;
    color: rgb(38, 38, 38);
}