p.lack-of-matches {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    gap: 10px;
}

p.lack-of-matches .sad-icon {
    font-size: 24px;
}