form.create-room-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
}
form.create-room-panel .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 20px;
}
form.create-room-panel .payment-icon {
    font-size: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
}
form.create-room-panel .info {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(146, 145, 145);
}



