@media screen and (max-width: 500px) {
    div.increase-room-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: 80px;
        background-color: #fff;
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: 0.5s;
        -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
        border-radius: 15px;
    }
 }
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.increase-room-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 400px;
            height: 80px;
            background-color: #fff;
            margin-top: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            transition: 0.5s;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            border-radius: 15px;
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        div.increase-room-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 450px;
            height: 80px;
            background-color: #fff;
            margin-top: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            transition: 0.5s;
            -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
            border-radius: 15px;
        }
    }




div.increase-room-panel.active {
    height: 300px;
}
div.increase-room-panel .title {
    margin-top: 20px;
    margin-bottom: 20px;
}

 div .increase-room-panel  .increase-room-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    min-height: 60px;
    border-radius: 15px;
    background-color: rgb(0, 0, 0);
    color: white;
    font-weight: 300;
    -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    cursor: pointer;

}
div .increase-room-panel .pay-room-players-button {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 95%;
    height: 60px;
    border-radius: 15px;
    background-color: black;
    color: white;
    font-weight: 300;
    -webkit-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 10px 14px -10px rgba(66, 68, 90, 1);
    cursor: pointer;
}



div .increase-room-panel  .players-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 320px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    margin-bottom: 20px;
}


div .increase-room-panel .players-panel .icon-action{
    font-size: 35px;
    cursor: pointer;
}
div .increase-room-panel .pay-room-players-button .add-icon{
    font-size: 25px;
}

span.price, span.players, .pay-room-players-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none; 
}
