@media screen and (max-width: 500px) {
    form.create-account-panel {
        display: flex;
        font-family: "Poppins", sans-serif;
        flex-direction: column;
        width: 100%;
        padding: 30px 20px;
        background-color: #f6f8f8; 
        justify-content: center;
        align-items: center;
    }
}
   
   
@media screen and (min-width: 501px){
    form.create-account-panel {
        display: flex;
        font-family: "Poppins", sans-serif;
        flex-direction: column;
        width: 450px;
        padding: 30px 20px;
        background-color: #f6f8f8; 
        justify-content: center;
        align-items: center;
    }
}
form.create-account-panel .error-message {
    margin-top: 20px;
    color: red;
}