* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root {
  height: 100%;
}
body {
  height: 100vh;
  background-color: black;
}