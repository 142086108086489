@media screen and (max-width: 500px) {
    section.login-panel  .container-login {
        display: flex;
        font-family: "Poppins", sans-serif;
        flex-direction: column;
        width: 100%;
        padding: 30px 20px;
        background-color: #f6f8f8; 
    }
}
   
   
@media screen and (min-width: 501px){
    section.login-panel  .container-login {
        display: flex;
        font-family: "Poppins", sans-serif;
        flex-direction: column;
        width: 450px;
        padding: 30px 20px;
        background-color: #f6f8f8; 
    }
}
   

section.login-panel {
    display: flex;
    font-family: "Poppins", sans-serif;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #f6f8f8; 
    border-radius: 30px 30px 0px 0px;
    overflow: hidden;
}



section.login-panel p {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(38, 38, 38);
}
