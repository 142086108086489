@media screen and (max-width: 500px) {
    div.room-info-panel {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 90%;
        padding: 20px 10px;
        background-color: white;
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    
    }
 }
    
    @media screen and (min-width: 501px) and (max-width: 700px) {
        div.room-info-panel {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 400px;
            padding: 20px 10px;
            background-color: white;
            border-radius: 15px;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        
        }
    }
    
    
    @media screen and (min-width: 701px)  {
        div.room-info-panel {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 450px;
            padding: 20px 10px;
            background-color: white;
            border-radius: 15px;
            -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
            box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
        
        }
    }




div.room-info-panel p {
    margin-bottom: 20px;
    font-size: 20px
}

div.room-info-panel span.number-of-players {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #000000;
    color: white;
    font-weight: 300;
    gap: 10px;
}


div.room-info-panel span.number-of-players span {
    display: flex;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    color: black;
    font-weight: 300;
    padding: 10px;
}