
    span.custom-create-button-active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 60px;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        border: none;
        border-radius: 15px;
        background-color: rgb(0, 0, 0);
        color: white;
        cursor: pointer;
        font-weight: 300;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    
    span.custom-create-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 60px;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        border: none;
        border-radius: 15px;
        background-color: rgb(193, 190, 190);
        color: white;
        cursor: pointer;
        font-weight: 300;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    
    
    
    




