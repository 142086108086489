form.login-content span.go-to-register-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    height: 60px;
    border-radius: 15px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    font-size: 17px;

}
form.login-content .title-login {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(38, 38, 38);
    margin: 30px 0px 50px 0px;
}


form.login-content span.go-to-reset-button{
    display: flex;
    justify-content: end;
    margin-top: -20px;
    padding-right: 10px;
    margin-bottom: 30px;
    font-style: italic;
    cursor: pointer;
    font-size: 15px;
}

.loading-icon {
    display: flex;
    color: white;
    margin: 10px;
    font-size: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


