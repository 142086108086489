.decision-modal {
    display: flex;
    padding: 20px;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%,-50%);
    gap: 10px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 14px -10px rgba(66, 68, 90, 1);
    outline: none;
}

.decision-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(118, 116, 116, 0.262); */
    background-color: rgba(46, 46, 46, 0.398);

  }
  div.decision-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
  }
  div.decision-icon-wrap .icon-modal {
    height: 70px;
    width: 70px;
    animation-name: pulse;
    animation-duration: 1s;
  }


  span.decision-message-modal {
    display: flex;

    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
  }

  div.decision-modal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    width: 120px;
    height: 40px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }


  @keyframes pulse {
    0%   {height: 50px; width: 50px;}
    25%  {height: 70px; width: 70px;}
    50%  {height: 50px; width: 50px;}
    100% {height: 70px; width: 70px;}
  }