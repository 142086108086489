nav {
    display: flex;
}

div.App {
    height: 100%;
}


footer {
    width: 100%;
    height: 80px;
    /* background-color: rgb(36, 36, 36); */
    background-color: rgb(14, 14, 14);

}
* {
    font-family: "Poppins", sans-serif;
}

body.menu-open {
    overflow: hidden;
}
